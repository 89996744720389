<template>
  <div class="log-con">
    <b-overlay :show="show" rounded="lg" :opacity="1">
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label" class="log-out-text">
            Signed out successfully, redirecting to authentication window.
            Please wait....testing only...
          </p>
        </div>
      </template>

    </b-overlay>
  </div>
</template>
<script>
export default {
  name: "Logout",
  data() {
    return {
      show: this,
    };
  },
  beforeCreate() {
    setTimeout(function () { this.$router.push("/"); }.bind(this), 1000)

  },
};
</script>
<style>
.log-con {
  margin-top: 80px;
}
</style>